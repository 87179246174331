/* GENERAL */
@font-face {
    font-family: 'Change your name';
    font-style: normal;
    font-weight: normal;
    src: local('Change your name'), url('../../public/fonts/change_your_name.ttf') format('ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* HEADER */
*{font-family:'Roboto';}
.header img{width:100%;height:auto;}

/* CERVEZAS */
.cervezas_images{display:flex;justify-content:center;align-items:center;}
.cervezas_images img{margin-right:5rem;}
.cervezas_images img:nth-child(3){margin-right:0;}
.cervezas_t1,.cervezas_t2{text-align:center;margin:auto;}
.cervezas_t1{font-weight:800;margin-top:3rem;}
.cervezas_t2{font-weight:400;color:#098dab;margin-top:.2rem;}

/* KNOW OUR STORY */
.story{position:relative;}
.story img:nth-child(1){position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.story_des{position:relative;z-index:2;display:flex;color:#fff;text-align:right;align-items:center;}
.story_des_content div{margin-left:auto;}
.story_des_t1{color:#04afd5;}
.story_des_t2{font-weight:800;}
.story_fotos img{position:relative;}
.story_fotos{display:flex;margin-top:1rem;justify-content:flex-end;position:relative;}

/* SHOP */
.shop_t1, .shop_t2, .merchandise_t1, .merchandise_t2{margin:auto;text-align:center;}
.shop_t1, .merchandise_t1{font-weight:800;margin-bottom:.5rem;}
.shop_t2, .merchandise_t2{color:#0a8dab;font-weight:300;}
.shop_content{display:flex;flex-wrap:wrap;margin-top:2rem;}
.producto{width:33%;padding-right:1rem;}
.producto img{margin:auto;margin-bottom:1rem;}
.producto_name{border:1px solid #0a8dab;padding:.5rem;text-align:center;font-weight:600;}
.producto_des{border:1px solid #0a8dab;border-top:0;display:flex;}
.producto_des_precio, .producto_des_link{width:50%;padding:.5rem;text-align:center;}
.producto_des_precio{border-right:1px solid #0a8dab;font-weight:500;}
.producto_des_link{text-decoration:none;color:#0a8dab;}

/* MERCHANDISE */
.merchandise_content{display:flex;justify-content:center;margin-top:2rem;}
.merchandise_el{width:33%;padding-right:1rem;}
.merchandise_el img{width:100%;height:auto;margin-bottom:1rem;}
.merchandise_el_subtitle, .merchandise_el_title, .merchandise_el_des{margin-bottom:1rem;}
.merchandise_el_subtitle{color:#0a8dab;}
.merchandise_el_title{font-weight:600;}
.merchandise_el_des{color:#444444;}

/* FOLLOW US */
.follow{display:flex;align-items:center;}
.follow_des_t1{color:#0a8dab;}
.follow_des_t2{font-weight:600;}
.follow_des_social{margin-top:1rem;position:relative;width:fit-content;}
.follow_des_social img{width:9rem!important;}
.follow_des_social a{position:absolute;top:0;left:0;width:3rem;height:100%;}
.follow_des_social a:nth-child(2){left:33.3%;}
.follow_des_social a:nth-child(3){left:66.6%;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .cervezas{padding:3rem 1rem;overflow:hidden;}
    .cervezas_images img{width:9rem;margin-right:3rem;}
    .cervezas_t1{font-size:1.6rem;max-width:21rem;}
    .cervezas_t2{font-size:1.1rem;}

    .story{padding:2rem 1rem;}
    .story_des{flex-wrap:wrap;}
    .story_des_content, .story_des img{width:100%;}
    .story_des_content{text-align:left;margin-bottom:1rem;}
    .story_des_content div{margin-left:initial;}
    .story_des_t1, .story_des_t3{font-size:1.1rem;}
    .story_des_t1{margin-bottom:.5rem;}
    .story_des_t2{font-size:2rem;}
    .story_des_sep{width:100%;height:.15rem;background-color:#fff;margin:1rem 0;}
    .story_fotos{display:none;}

    .shop, .merchandise{padding:2rem 1rem;}
    .shop_t1, .merchandise_t1{font-size:1.8rem;}
    .shop_t2, .merchandise_t2{font-size:1rem;}

    .merchandise_el_subtitle, .merchandise_el_des{font-size:.8rem;}
    .merchandise_el_title, .merchandise_el_price{font-size:1.1rem;}
    .merchandise_content{flex-wrap:wrap;}
    .merchandise_el{width:100%;margin-bottom:2rem;padding-right:0;}
    .merchandise_el:nth-child(3){margin-bottom:0;}

    .producto{width:100%;margin-bottom:3rem;padding-right:0;}
    .producto:nth-child(3){margin-bottom:0;}
    .producto img{width:13rem;}
    .producto_name{font-size:1.2rem;}
    .producto_des_precio, .producto_des_link{font-size:1rem;}

    .follow{padding:2rem 1rem;flex-direction:column-reverse;}
    .follow img:nth-child(1){width:100%;height:auto;}
    .follow img:nth-child(2){display:none;}
    .follow_des{width:100%;margin-bottom:1rem;}
    .follow_des_t2{font-size:2.2rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .cervezas{padding:3rem 2rem;}
    .cervezas_images img{width:9rem;margin-right:3rem;}
    .cervezas_t1{font-size:1.8rem;max-width:27rem;}
    .cervezas_t2{font-size:1.1rem;}

    .story{padding:2rem;}
    .story_des{flex-wrap:wrap;}
    .story_des_content, .story_des img{width:100%;}
    .story_des_content{text-align:left;margin-bottom:2rem;}
    .story_des_content div{margin-left:initial;}
    .story_des_t1, .story_des_t3{font-size:1.3rem;max-width:32rem;}
    .story_des_t1{margin-bottom:.5rem;}
    .story_des_t2{font-size:2.2rem;max-width:22rem;}
    .story_des_sep{max-width:34rem;height:.2rem;background-color:#fff;margin:.7rem 0;}
    .story_fotos{display:none;}

    .shop, .merchandise{padding:3rem 2rem;}
    .shop_t1, .merchandise_t1{font-size:1.8rem;}
    .shop_t2, .merchandise_t2{font-size:1.1rem;max-width:25rem;}
    .merchandise_t2{max-width:32rem;}

    .merchandise_el_subtitle, .merchandise_el_des{font-size:.8rem;}
    .merchandise_el_title, .merchandise_el_price{font-size:1.1rem;}

    .producto img{width:13rem;}
    .producto_name{font-size:1.2rem;}
    .producto_des_precio, .producto_des_link{font-size:1rem;}

    .follow{padding:3rem 2rem;}
    .follow img:nth-child(1){width:60%;height:auto;}
    .follow img:nth-child(2){display:none;}
    .follow_des{flex-grow:1;padding-left:1rem;}
    .follow_des_t2{font-size:2.2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .cervezas{padding:4rem 3rem;}
    .cervezas_images img{width:10rem;}
    .cervezas_t1{font-size:1.9rem;max-width:28rem;}
    .cervezas_t2{font-size:1.2rem;}

    .story{padding:1rem;}
    .story_des_content, .story_des img{width:50%;}
    .story_des_content{padding-right:1rem;}
    .story_des_t1, .story_des_t3{font-size:1.1rem;max-width:30rem;}
    .story_des_t1{margin-bottom:.5rem;}
    .story_des_t2{font-size:2rem;max-width:20rem;}
    .story_des_sep{width:28rem;height:.2rem;background-color:#fff;margin:.7rem 0;}
    .story_fotos img:nth-child(1){width:calc(50% - 1rem);}
    .story_fotos img:nth-child(2), .story_fotos img:nth-child(3){width:calc(25% - .5rem);}
    .story_fotos img:nth-child(3){margin-left:1rem;}

    .shop, .merchandise{padding:4rem 3rem;}
    .shop_t1, .merchandise_t1{font-size:2rem;}
    .shop_t2, .merchandise_t2{font-size:1.2rem;max-width:27rem;}
    .merchandise_t2{max-width:34rem;}

    .merchandise_el_subtitle, .merchandise_el_des{font-size:.9rem;}
    .merchandise_el_title, .merchandise_el_price{font-size:1.2rem;letter-spacing:1px;}

    .producto img{width:15rem;}
    .producto_name{font-size:1.2rem;}
    .producto_des_precio, .producto_des_link{font-size:1.1rem;}

    .follow{padding:4rem 3rem;}
    .follow img:nth-child(1){width:50%;height:auto;margin-right:1rem;}
    .follow img:nth-child(2){width:22.2%;height:auto;}
    .follow_des{flex-grow:1;padding-left:1rem;}
    .follow_des_t2{font-size:2.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .cervezas{padding:4rem;}
    .cervezas_images img{width:10rem;}
    .cervezas_t1{font-size:2rem;max-width:28rem;}
    .cervezas_t2{font-size:1.2rem;}

    .story{padding:1rem;}
    .story_des_content, .story_des img{width:50%;}
    .story_des_content{padding-right:2rem;}
    .story_des_t1, .story_des_t3{font-size:1.2rem;max-width:30rem;}
    .story_des_t1{margin-bottom:.5rem;}
    .story_des_t2{font-size:2rem;max-width:18rem;}
    .story_des_sep{width:30rem;height:.2rem;background-color:#fff;margin:1rem 0;}
    .story_fotos img:nth-child(1){width:calc(50% - 1rem);}
    .story_fotos img:nth-child(2), .story_fotos img:nth-child(3){width:calc(25% - .5rem);}
    .story_fotos img:nth-child(3){margin-left:1rem;}
    
    .shop, .merchandise{padding:4rem;}
    .shop_t1, .merchandise_t1{font-size:2rem;}
    .shop_t2, .merchandise_t2{font-size:1.2rem;max-width:27rem;}
    .merchandise_t2{max-width:34rem;}

    .merchandise_el_subtitle, .merchandise_el_des{font-size:.9rem;}
    .merchandise_el_title, .merchandise_el_price{font-size:1.2rem;letter-spacing:1px;}

    .producto img{width:15rem;}
    .producto_name{font-size:1.2rem;}
    .producto_des_precio, .producto_des_link{font-size:1.1rem;}

    .follow{padding:4rem;}
    .follow img:nth-child(1){width:50%;height:auto;margin-right:1rem;}
    .follow img:nth-child(2){width:22.2%;height:auto;}
    .follow_des{flex-grow:1;padding-left:3rem;}
    .follow_des_t2{font-size:2.5rem;}
}

@media (min-width:1400px){
    .cervezas{padding:5rem 4rem;}
    .cervezas_images img{width:10rem;}
    .cervezas_t1{font-size:2.2rem;max-width:29rem;}
    .cervezas_t2{font-size:1.4rem;}

    .story{padding:2rem;}
    .story_des_content, .story_des img{width:50%;}
    .story_des_content{padding-right:3rem;}
    .story_des_t1, .story_des_t3{font-size:1.3rem;max-width:32rem;}
    .story_des_t1{margin-bottom:.5rem;}
    .story_des_t2{font-size:2.2rem;max-width:20rem;}
    .story_des_sep{width:34rem;height:.2rem;background-color:#fff;margin:1rem 0;}
    .story_fotos{margin-top:2rem;}
    .story_fotos img:nth-child(1){width:calc(50% - 2rem);}
    .story_fotos img:nth-child(2), .story_fotos img:nth-child(3){width:calc(25% - 1rem);}
    .story_fotos img:nth-child(3){margin-left:2rem;}

    .shop, .merchandise{padding:4rem;max-width:1500px;margin:auto;}
    .shop_t1, .merchandise_t1{font-size:2.2rem;}
    .shop_t2, .merchandise_t2{font-size:1.3rem;max-width:29rem;}
    .merchandise_t2{max-width:36rem;}

    .merchandise_el_subtitle, .merchandise_el_des{font-size:1.1rem;}
    .merchandise_el_title, .merchandise_el_price{font-size:1.3rem;letter-spacing:1px;}

    .producto img{width:18rem;}
    .producto_name{font-size:1.4rem;}
    .producto_des_precio, .producto_des_link{font-size:1.2rem;}

    .follow{padding:4rem;max-width:1500px;margin:auto;}
    .follow img:nth-child(1){width:50%;height:auto;margin-right:1rem;}
    .follow img:nth-child(2){width:22.2%;height:auto;}
    .follow_des{flex-grow:1;padding-left:3rem;}
    .follow_des_t1{font-size:1.2rem;}
    .follow_des_t2{font-size:2.8rem;}
}