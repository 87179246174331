/* HEADER */
.header img{width:100%;height:auto;}

/* ADVENTURE */
.adventure{background-color:#f1f0eb;color:#393f41;position:relative;}
.adventure_bg{position:absolute;bottom:0;left:0;width:100%;height:100%;object-fit:cover;}
.adventure_bg2{position:absolute;}
.adventure_images{position:relative;}
.adventure_images img{position:absolute;}
.adventure_images_des{position:absolute;display:flex;align-items:center;justify-content:center;text-align:center;}
.adventure_bg2{width:18%;left:.45%;top:4rem;}
.adventure_images{height:0;padding-top:53%;}
.adventure_images img:nth-child(1){width:27%;left:0;bottom:0;z-index:2;}
.adventure_images img:nth-child(2){width:50%;left:15%;top:0;z-index:1;}
.adventure_images img:nth-child(3){width:34%;right:0;top:6.2%;z-index:1;}
.adventure_images_des{width:40.9%;left:27%;top:62.6%;height:37.4%;}

/* RIDE THE WAVE */
.ride img{width:100%;height:auto;}

/* FOTOS */
.fotos{background-color:#f1f0eb;position:relative;}
.fotos_content{position:relative;}
.fotos img:nth-child(1){position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.fotos_con{width:58%;height:auto;padding:.2rem 1rem 1rem 1rem;background-color:#fff;position:relative;}
.fotos_con img{width:100%;height:auto;}
.fotos_content .fotos_con:nth-child(2) .fotos_con_text{text-align:right;}
.fotos_content .fotos_con:nth-child(2){margin-left:auto;margin-top:-13%;z-index:1;}
.fotos_content .fotos_con:nth-child(1){z-index:2;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .adventure, .fotos{padding:2rem 1rem;}
    .fotos_content{display:flex;flex-direction:column-reverse;}
    .fotos_con{width:85%;padding:.5rem .5rem 1rem .5rem;}
    .fotos_con_text{position:absolute;bottom:0;font-size:.9rem;}
    .fotos_con:nth-child(2) .fotos_con_text{right:.5rem;}
    .fotos_content .fotos_con:nth-child(1){margin-top:-8%;z-index:1;}
    .fotos_content .fotos_con:nth-child(2){margin-top:0;}

    .adventure_images{padding-top:69%;}
    .adventure_images img:nth-child(1){display:none;}
    .adventure_images img:nth-child(2){width:75%;left:0;top:0;z-index:1;}
    .adventure_images img:nth-child(3){width:35%;right:0;top:27.5%;z-index:1;}
    .adventure_images_des{left:0;top:initial;bottom:0;width:67%;height:26%;padding:0 .2rem;font-size:1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .adventure, .fotos{padding:3rem 1.5rem;}
    
    .adventure_images_des{font-size:1.2rem;padding:0 1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .adventure, .fotos{padding:4rem 3rem;}

    .adventure_images_des{font-size:1.6rem;padding:0 .5rem;max-width:26rem;margin:auto;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .adventure, .fotos{padding:4rem;}

    .adventure_images_des{font-size:2.05rem;}
}

@media (min-width:1400px){
    .adventure, .fotos{padding:5rem 4rem;}

    .adventure_images{max-width:1400px;margin:auto;}
    .adventure_images_des{width:40.9%;left:27%;top:62.6%;height:37.4%;font-size:2.3rem;}

    .fotos_content{max-width:1400px;margin:auto;}
    .fotos_con_text{font-size:1.1rem;}
}

@media (min-width:calc(1400px + 8rem)) {
    .adventure_images{padding-top:742px;}
}