/* GENERAL STYLE */
@font-face {
    font-family: 'Andale Mono Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Andale Mono Regular'), url('../../public/fonts/ANDALEMO.woff') format('woff');
}
*{padding:0;margin:0;box-sizing:border-box;font-family: 'Andale Mono Regular';}
img, video, iframe, picture{display:block;}

/* NAVBAR */
.navbar{position:fixed;top:0;left:0;width:100%;z-index:99;transition:all .5s ease;}
.navbar_content{display:flex;align-items:center;}
.navbar.fixed{background-color:rgba(0, 0, 0, .5);}
.navbar.black.fixed{background-color:rgba(241, 240, 235, .8);}
.navbar_logo img{width:4.5rem;height:auto;}
.navbar_options{flex-grow:1;display:flex;justify-content:flex-end;align-items:center;}
.navbar_element{text-decoration:none;color:#fff;margin-right:1.5rem;}
.navbar_options.black .navbar_element{color:#000}
.navbar_element:nth-child(3){margin-right:0;background-color:#f1f0eb;color:#000;padding:.5rem 1rem;border-radius:.2rem;}
.navbar_element:nth-child(3){margin-right:0;background-color:#000;color:#f1f0eb!important;padding:.5rem 1rem;border-radius:.2rem;}

/* FOOTER */
.footer{position:relative;color:#fff;}
.footer_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:1;}
.footer_shadow{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, .55);z-index:2;}
.footer_logo, .footer_el{position:relative;z-index:3;text-align:center;}
.footer_content{display:flex;flex-wrap:wrap;}
.footer_logo{width:100%;margin-bottom:2rem;}
.footer_logo img{margin:auto;}
.footer_story_title{font-weight:bold;margin-bottom:.5rem;}
.footer .copyright{width:100%;border-top:.1rem solid rgba(255, 255, 255, .5);position:relative;z-index:3;margin-top:3rem;padding-top:1rem;text-align:center;}
.footer_contact_el{display:block;text-decoration:none;color:#fff;margin-bottom:.5rem;}
.footer_menu_el{display:block;text-decoration:none;color:#fff;margin-bottom:.5rem;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .navbar{padding:.5rem .8rem;font-size:.9rem;}
    .navbar_logo img{width:4rem;}
    .navbar_element{margin-right:.8rem;}
    .navbar_element:nth-child(3){padding:.4rem .8rem;}

    .footer{padding:2rem 1rem;}
    .footer_logo img{width:5rem;height:auto;}
    .footer_content{justify-content:center;}
    .footer_el{width:100%;padding:0 1rem;margin-bottom:1rem;}
    .footer_el:nth-child(4){margin-bottom:0;}
    .footer_story_title{font-size:1.3rem;}
    .footer .copyright{margin-top:1.5rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .navbar{padding:.5rem 2rem;font-size:1rem;}

    .footer{padding:3rem 2rem;}
    .footer_logo img{width:6rem;height:auto;}
    .footer_content{justify-content:center;}
    .footer_el{padding:0 1rem;}
    .footer_story{width:51%;margin-bottom:1rem;}
    .footer_contact, .footer_menu{width:50%;}
    .footer_story_title{font-size:1.3rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .navbar{padding:.5rem 3rem;font-size:1.1rem;}

    .footer{padding:3rem;}
    .footer_logo img{width:7rem;height:auto;}
    .footer_el{padding:0 1rem;}
    .footer_story, .footer_menu{width:35%;}
    .footer_contact{width:30%;}
    .footer_story_title{font-size:1.3rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .navbar{padding:.5rem 4rem;font-size:1.2rem;}

    .footer{padding:4rem;}
    .footer_logo img{width:8rem;height:auto;}
    .footer_el{padding:0 1rem;}
    .footer_story, .footer_menu{width:35%;}
    .footer_contact{width:30%;}
    .footer_story_title{font-size:1.3rem;}
}

@media (min-width:1400px){
    .navbar_content{max-width:1500px;margin:auto;}
    .navbar{padding:.7rem 4rem;}
    .navbar{font-size:1.2rem;}
    .navbar_logo img{width:5rem;}

    .footer{padding:4rem;}
    .footer_content{max-width:1500px;margin:auto;}
    .footer_logo img{width:8rem;height:auto;}
    .footer_el{padding:0 1rem;}
    .footer_story, .footer_menu{width:35%;}
    .footer_contact{width:30%;}
    .footer_story_title{font-size:1.3rem;}
}